import "./index.scss";

import NIQ_Logo from "../../../assets/NIQ_Logo.png";
import NexIQ_Logo from "../../../assets/NexIQ_Logo.png"
import binanceLogo from "../../../assets/binance.png";
import btclogo from "../../../assets/btclogo.png";
import ethlogo from "../../../assets/ethlogo.png";
import trc20Logo from "../../../assets/trc20.png";
import { HashLink as Link } from "react-router-hash-link";

const Footer = () => {

  return (
    <footer className="app-footer">
      <div className="primary-footer">
        <div className="container">
          <div className="row  align-items-center">
            <div
              className="col-md-6 col-lg-3 col-12 wow fadeInUp"
              data-wow-delay=".2s"
              //   style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;"
            >
              <a
                className="navbar-brand me-5 d-flex flex-column"
                href="/"
              >
                <div className="d-flex align-items-center">
                <img src={NexIQ_Logo} alt="NexIQ Logo" width={80} height={80}/>
                <p className="title text-white fs-5 mt-3">NexIQ(USN)</p>
                </div>
                <div className="d-flex align-items-center">
                <img src={NIQ_Logo} alt="NIQ Logo" width={80} height={80}/>
                <p className="title text-white fs-5 mt-3">NIQ(NIQ)</p>

                </div>


                {/* <img className="logo" src={logo} alt="LOGO" /> */}
                {/* <h3 className="text-black">NexIQ</h3> */}
              </a>
              {/* <h4 className="text-uppercase">NexIQ</h4> */}
              {/* <p>
                Subscribe to our newsletter to receive news, updates, free stuff
                and new releases by email. We don't do spam.
              </p> */}
              {/* <p className="copyright">© Copyright 2018 VectorLab, Inc.™</p> */}
            </div>
            {/* <div
              className="col-md-4 mb-md-0 mb-3 wow fadeInUp"
              data-wow-delay=".3s"
              //   style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
            >
              <h6 className="text-uppercase">Resources</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="/">Free schedules</a>
                </li>
                <li>
                  <a href="/">terms &amp; privacy</a>
                </li>
                <li>
                  <a href="/">api reference</a>
                </li>
                <li>
                  <a href="/">help desk</a>
                </li>
              </ul>
            </div> */}
          
            <div
              className="col-md-6 col-12 wow fadeInUp"
              data-wow-delay=".4s"
              //   style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;"
            >
            
              <ul className="list-unstyled ">
                <li className="ms-0  ms-lg-5">
                  <Link className="text-decoration-none" to="/">Home</Link>
                </li>
                <li className="ms-0  ms-lg-5">
                  <Link className="text-decoration-none" to="#roadmap">Roadmap</Link>
                </li>
                <li className="ms-0  ms-lg-5">
                  <Link className="text-decoration-none" to="#about">Tokenomics</Link>
                </li>
                <li className="ms-0  ms-lg-5">
                  <Link className="text-decoration-none" to="#contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="secondary-footer text-md-left text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mb-md-0 mb-3">
              <div
                className="payment-cards wow fadeInUp"
                data-wow-delay=".45s"
                // style="visibility: visible; animation-delay: 0.45s; animation-name: fadeInUp;"
              >
                <img
                  src="https://thevectorlab.net/kryplab/assets/img/fc1.png"
                  alt=""
                />
                <img
                  src="https://thevectorlab.net/kryplab/assets/img/fc2.png"
                  alt=""
                />
                <img
                  src="https://thevectorlab.net/kryplab/assets/img/fc3.png"
                  alt=""
                />
                <img
                  src="https://thevectorlab.net/kryplab/assets/img/fc4.png"
                  alt=""
                />
                <img
                  src={binanceLogo}
                  alt="Binance Logo"
                  width={40}
                  height={40}
                />
                <img src={trc20Logo} alt="Trc20 Logo" width={40} height={40} />
                <img src={ethlogo} alt="Ethereum Logo" width={55} height={55} />
                <img src={btclogo} alt="Btc Logo" width={35} height={35} />
              </div>
            </div>
            <div
              className="col-md-5 text-md-right wow fadeInUp"
              data-wow-delay=".5s"
              //   style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;"
            >
              <div className="float-md-right  mb-md-0 mb-3"></div>
              <div className="social-links float-md-right">
                <a href="/">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="/">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="/">
                  <i className="fa fa-bitcoin"></i>
                </a>
                <a href="/">
                  <i className="fa fa-youtube"></i>
                </a>
                <a href="/">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="/">
                  <i className="fa fa-google-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
