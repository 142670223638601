import "./index.scss";
import about1 from "../../../assets/about1.png";
import about2 from "../../../assets/about2.png";
import about3 from "../../../assets/about3.png";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import collateral1 from "../../../assets/collateral.png";
import collateralRatio from "../../../assets/collateralRatio.png";

// Register the plugin with Chart.js

const AboutUs = () => {
  const data = {
    labels: ["USN", "DAI", "USDT", "TUSD", "USDC"],
    datasets: [
      {
        label: "Collateral Ratio",
        data: [245.1, 120, 100, 100, 100],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
        barThickness: 40,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Ensure the chart takes full height
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: (value, context) => {
          return `${context.chart.data.labels[context.dataIndex]}: ${value}%`;
        },
        font: {
          weight: "bold",
        },
        color: "white",
      },
      tooltip: {
        enabled: true,
      },
    },
    elements: {
      bar: {
        borderRadius: 20, // round the corners
        borderSkipped: false, // ensures that the top corners are rounded as well
      },
    },
  };
  Chart.register(ChartDataLabels);

  return (
    <div id="about">
      <section className="section-gap-sm">
        <div className="container">
          <div className="d-flex g-2 flex-column flex-lg-row newRow">
            <div className="col-md-6 mb-md-0 mb-3 border border-white p-4 rounded ms-2 newCol">
              <div className="row text-center mb-lg-0 mb-5">
                <h1 className="title text-white mb-4">NIQ</h1>
                <div className="col-6 mt-lg-5 pt-lg-5 mt-0 pt-0">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>NIQ (NIQ)</h3>
                          <p className="mb-0">Token Name</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>TRC20</h3>
                          <p className="mb-0">Token Type</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".5s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.5s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>1% Per Transaction</h3>
                          <p className="mb-0">Burn Rate</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.4s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>240,000,000 NIQ</h3>
                          <p className="mb-0">Total Supply</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".5s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.5s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>1 NIQ = 3.98 USDT</h3>
                          <p className="mb-0">Initial Price</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".5s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.5s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>0.469 Token Per Transaction</h3>
                          <p className="mb-0">Reward Amount</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-md-0 mb-3 border border-white p-4 rounded newCol">
              <div className="row text-center mb-lg-0 mb-5">
                <h1 className="title text-white mb-4">USN</h1>
                <div className="col-6 mt-lg-5 pt-lg-5 mt-0 pt-0">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>NexIQ (USN)</h3>
                          <p className="mb-0">Token Name</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>TRC20</h3>
                          <p className="mb-0">Token Type</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".5s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.5s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>1% Per Transaction</h3>
                          <p className="mb-0">Burn Rate</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        className="card box-show py-md-5 wow fadeInUp newCard"
                        data-wow-delay=".5s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.5s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="card-body">
                          <h3>0.469 Token Per Transaction</h3>
                          <p className="mb-0">Reward Amount</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Uncomment and modify this section as needed */}
            {/* <div className="col-lg-7 col-md-12 col-gap">
              <h2
                className="mb-md-5 wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUp",
                }}
              >
                NexIQ
              </h2>
              <p
                className="wow fadeInUp"
                data-wow-delay=".3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                We are at the forefront of merging the traditional value of gold
                with the modern appeal of cryptocurrency. Our mission is to
                provide a stable, gold-backed cryptocurrency that offers the
                best of both worlds. With our roots in the gold mining industry
                in Ghana and our eyes set on the digital horizon, we aim to
                redefine investment for the next generation.
              </p>
              <div className="row">
                <div
                  className="col-md-8 wow fadeInUp"
                  data-wow-delay=".25s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.25s",
                    animationName: "fadeInUp",
                  }}
                >
                  <h5 className="text-paste-color">Token Distribution</h5>
                  <ul className="list-unstyled">
                    <li>- Public Sale: 40% (4,000,000 NIQ)</li>
                    <li>- Team and Founders: 20% (2,000,000 NIQ)</li>
                    <li>- Reserve Fund: 15% (1,500,000 NIQ)</li>
                    <li>- Partnerships and Advisors: 10% (1,000,000 NIQ)</li>
                    <li>- Community and Marketing: 10% (1,000,000 NIQ)</li>
                    <li>- Staking Rewards: 5% (500,000 NIQ)</li>
                  </ul>
                </div>
                <div
                  className="col-md-4 wow fadeInUp"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInUp",
                  }}
                >
                  <h5 className="text-paste-color">Token Utility</h5>
                  <ul className="list-unstyled">
                    <li>- Gold Redemption</li>
                    <li>- Staking</li>
                    <li>- Payments</li>
                    <li>- Access</li>
                    <li>- Voting Rights</li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="container-fluid text-light py-5">
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <h1 className="text-info">USN</h1>
                    <h2>Over-Collateralized Decentralized Stablecoin</h2>
                  </div>

                  <div
                    className="col-lg-6 col-12"
                    style={{
                      backgroundImage: `url(${about1})`,
                      backgroundRepeat: "no-repeat",
                      paddingTop: "4rem",
                      backgroundSize: "3.75rem",
                    }}>
                    <h3 className="text-info">Over-Collateralized</h3>
                    <p style={{ fontSize: "0.9rem" }}>
                      USN is secured by the over-collateralization of multiple
                      mainstream digital assets (e.g. TRX, BTC, and USDT). The
                      total value of collateralized assets is significantly
                      higher than that of USN in circulation with the collateral
                      ratio set at 120%.
                    </p>
                  </div>
                  <div
                    className="col-lg-6 col-12"
                    style={{
                      backgroundImage: `url(${about2})`,
                      backgroundRepeat: "no-repeat",
                      paddingTop: "4rem",
                      backgroundSize: "3.75rem",
                    }}>
                    <h3 className="text-info">Decentralized</h3>
                    <p style={{ fontSize: "0.9rem" }}>
                      The USN protocol aims to provide the blockchain industry
                      with the most stable, decentralized, tamper-proof, and
                      freeze-free stablecoin system, a perpetual system
                      independent from any centralized entity.
                    </p>
                  </div>
                  <div
                    className="col-lg-6 col-12"
                    style={{
                      backgroundImage: `url(${about3})`,
                      backgroundRepeat: "no-repeat",
                      paddingTop: "4rem",
                      backgroundSize: "3.75rem",
                    }}>
                    <h3 className="text-info">Chain-Agnostic</h3>
                    <p style={{ fontSize: "0.9rem" }}>
                      USN can now circulate on multiple mainstream networks.
                      Users can transfer USN across different networks via
                      cross-chain bridges. Similar to TRC20-USDT, USN caters to
                      users' needs by offering a fast and affordable experience
                      on TRON.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 position-relative ">
                  <div className="graph">
                    <Bar data={data} options={options} />
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" text-center card transparency">
      <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col">
          <div className="p-4 ">
            <h2 className="text-white">Transparency</h2>
            <h1 className="text-white fs-1 fw-bolder">$  739,451,251</h1>
            <p className="text-white">Total USN Supply</p>
          
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-lg-8 col-12">
          <div className="card p-5 bg-dark rounded-4  text-white" style={{backgroundImage:`url(${collateral1})`, backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
            <h4>Total Collateral</h4>
            <h3 className="text-white fw-bolder fs-1">$1,809,585,324</h3>
            <div className="row mt-3">
              <div className="col">
                <p>TRX</p>
                <p>10,929,602,300</p>
              </div>
              <div className="col">
                <p>BTC</p>
                <p>12,837.21</p>
              </div>
              <div className="col">
                <p>USDT</p>
                <p>14,119,213</p>
              </div>
              <div className="col">
                <p>USDC</p>
                <p>0</p>
              </div>
              <div className="col">
                <p>USDJ</p>
                <p>0</p>
              </div>
              <div className="col">
                <p>TUSD</p>
                <p>0</p>
              </div>
            </div>
           
          </div>
        </div>
        <div className="col-lg-4 col-12 mt-2 mt-lg-0">
        <div className="card p-5 bg-dark rounded-4 text-white" style={{backgroundImage:`url(${collateralRatio})`, backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>

            <h4>Collat. Ratio</h4>
            <h3 className="text-white fw-bolder fs-1">244.72%</h3>
            <p>
              The stability and security of USN are ensured by the
              over-collateralization of multiple mainstream cryptocurrencies.
            </p>
          </div>
      </div>
      </div>
      </div>
    </div>
      </section>
    </div>
  );
};

export default AboutUs;
