import React from "react";
import collateral1 from "../../../assets/collateral.png";
import collateralRatio from "../../../assets/collateralRatio.png";

const TransparencyComponent = () => {
  return (
<>
</>
  );
};

export default TransparencyComponent;
