// src/PageNotFound.js
import React from 'react';
import { Layout, Typography, Button } from 'antd';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph, Text } = Typography;

const PageNotFound = () => {
  return (
    <Layout style={{ minHeight: '100vh', background: '#f0f2f5' }}>
      <Header style={{ background: '#071129', padding: '0 20px' }} className="text-center">
        <Title style={{ color: '#fff', lineHeight: '64px', fontSize: '36px' }} level={2}>Page Not Found</Title>
      </Header>
      <Content className="d-flex justify-content-center align-items-center flex-column" style={{ minHeight: 'calc(100vh - 130px)', padding: '20px' }}>
        <Title level={3} style={{ color: '#ff4d4f', marginBottom: '20px' }}>404 - Page Not Available</Title>
        <Paragraph style={{ fontSize: '18px', textAlign: 'center' }}>
          <Text strong style={{ fontSize: '20px', color: '#faad14' }}>Connect your wallet to swap</Text>
        </Paragraph>
        <Paragraph style={{ fontSize: '16px', textAlign: 'center' }}>
          The page you are looking for does not exist. Please check the URL or go back to the homepage.
        </Paragraph>
        <Button type="primary" size="large" style={{ marginTop: '20px' }} href="/">
          Go to Homepage
        </Button>
      </Content>
      <Footer className="text-center py-3" style={{ background: '#071129', color: '#fff' }}>
        ©2024 NIQ Token. All Rights Reserved.
      </Footer>
    </Layout>
  );
};

export default PageNotFound;
