import React, { useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

const CursorDiv = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
//   margin: -250px 0 0 -250px;
  box-shadow: inset -25px -25px 50px #152758, inset 25px 25px 50px #000000;
  will-change: transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 0;
  top: 0;
  left: 0;
  visibility:hidden;
`;

const Cursor = () => {
  useEffect(() => {
    const handleMouseMove = (event) => {
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      gsap.set(".cursor", {
        x: mouseX,
        y: mouseY
      });

      gsap.to(".shape", {
        x: mouseX,
        y: mouseY,
        stagger: -0.05
      });
    };

    document.body.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return <CursorDiv className="cursor" />;
};

export default Cursor;
