import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import React, { createContext, useState, useEffect } from "react";
// import { WalletConnectWallet, WalletConnectChainID } from "@tronweb3/walletconnect-tron";
// import TronWeb from "tronweb";

const BalanceContext = createContext();

export const BalanceProvider = ({ children }) => {
  const {address} = useWallet();
  const [balanceTRX, setBalanceTRX] = useState(null);
  const [balanceUSDT, setBalanceUSDT] = useState(null);
  // const projectId = "890ea895390f24c9d20bf8c7509596a5";

  const fetchBalances = async () => {
    try {
      if (!address) {
        console.error("No address found");
        return;
      }

      let tronWeb;
      if (window.tronWeb && window.tronWeb.ready) {
        tronWeb = window.tronWeb;
      } 
    

      // Fetch TRX Balance
      const balanceInSun = await tronWeb.trx.getBalance(address);
      const balanceInTrx = tronWeb.fromSun(balanceInSun);
      console.log(balanceInTrx,"balance in TRX");
      setBalanceTRX(balanceInTrx);

      // Fetch USDT Balance
      const contractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"; // USDT contract address on Tron
      const contract = await tronWeb.contract().at(contractAddress);
      const balanceInUsdt = await contract.methods.balanceOf(address).call();
      console.log(tronWeb.fromSun(balanceInUsdt),"balance in USDT");
      setBalanceUSDT(tronWeb.fromSun(balanceInUsdt));
    } catch (error) {
      console.error("Failed to fetch balances:", error);
    }
  };

  // const tronWallet = new WalletConnectWallet({
  //   network: WalletConnectChainID.Mainnet,
  //   options: {
  //     relayUrl: "wss://relay.walletconnect.com",
  //     projectId: projectId,
  //     metadata: {
  //       name: "JustLend",
  //       description: "JustLend WalletConnect",
  //       url: "https://app.justlend.org/",
  //       icons: ["https://app.justlend.org/mainLogo.svg"],
  //     },
  //   },
  //   web3ModalConfig: {
  //     themeMode: "dark",
  //     themeVariables: {
  //       "--w3m-z-index": 1000,
  //     },
  //     explorerRecommendedWalletIds: [
  //       "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
  //       "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  //     ],
  //   },
  // });

  useEffect(() => {
    if (address) {
      fetchBalances();
    }
  }, [address]);

  // const connectWallet = async () => {
  //   try {
  //     const response = await tronWallet.connect();
  //     setaddress(response.address);
  //   } catch (error) {
  //     console.error("Error connecting to tronWallet:", error);
  //   }
  // };

  // const signTransaction1 = async () => {
  //   try {
  //     // Ensure address is set
  //     if (!address) {
  //       throw new Error("Tron address is not set");
  //     }

  //     // Ensure tronWeb is available
  //     let tronWeb;
  //     if (window.tronWeb && window.tronWeb.ready) {
  //       tronWeb = window.tronWeb;
  //     } else {
  //       tronWeb = new TronWeb({
  //         fullHost: "https://api.trongrid.io",
  //         headers: { "TRON-PRO-API-KEY": "f51d9ea7-ce4f-4924-83e6-58bb728a9ee3" }
  //       });
  //     }

  //     // Create a transaction object
  //     const fromAddress = address;
  //     const amountInSun = "1000000"; // Amount in SUN (1 TRX = 1,000,000 SUN)
  //     const toAddress = "TPEv6MePZ6qApquDYcGHR3S6ZQzQbNNmK2";

  //     // Ensure the transaction is in the correct format
  //     const unsignedTx = await tronWeb.transactionBuilder.sendTrx(
  //       toAddress,
  //       amountInSun,
  //       address
  //     );

  //     // Sign the transaction using WalletConnect
  //     const signedTransaction = await tronWallet.signTransaction(unsignedTx);

  //     // Broadcast the signed transaction
  //     const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);

  //     if (broadcast.result) {
  //       console.log("Transaction successfully broadcasted:", broadcast);
  //     } else {
  //       throw new Error("Transaction failed");
  //     }
  //   } catch (error) {
  //     console.error("Failed to sign and broadcast transaction:", error);
  //   }
  // };

  // const sendTrxToAddress = async () => {
  //   try {
  //     // Ensure address is set
  //     if (!address) {
  //       throw new Error("Tron address is not set");
  //     }

  //     // Ensure tronWeb is available
  //     let tronWeb;
  //     if (window.tronWeb && window.tronWeb.ready) {
  //       tronWeb = window.tronWeb;
  //     } else {
  //       tronWeb = new TronWeb({
  //         fullHost: "https://api.trongrid.io",
  //         headers: { "TRON-PRO-API-KEY": "f51d9ea7-ce4f-4924-83e6-58bb728a9ee3" }
  //       });
  //     }

  //     const toAddress = "TBEVrBCwo9BvJiUEsBi2Vk9sH5xYzB8S1U";
  //     const amountInSun = tronWeb.toSun(10); // Amount to send (10 TRX)

  //     // Build send TRX transaction
  //     const unsignedTx = await tronWeb.transactionBuilder.sendTrx(
  //       toAddress,
  //       amountInSun,
  //       address
  //     );

  //     console.log("Unsigned Transaction:", unsignedTx);

  //     // Sign the transaction using WalletConnect
  //     const signedTransaction = await tronWallet.signTransaction(unsignedTx);

  //     console.log("Signed Transaction:", signedTransaction);

  //     // Broadcast the signed transaction
  //     const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);

  //     if (broadcast.result) {
  //       console.log("TRX sent successfully:", broadcast);
  //     } else {
  //       throw new Error("Sending TRX failed");
  //     }
  //   } catch (error) {
  //     console.error("Failed to send TRX:", error);
  //   }
  // };

  return (
    <BalanceContext.Provider
      value={{
        address,
        balanceTRX,
        balanceUSDT,
        // connectWallet,
        fetchBalances,
        // signTransaction1,
        // sendTrxToAddress
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export default BalanceContext;
