import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import NexIQ_Logo from "../../../assets/NexIQ_Logo.webp";
import "./index.scss";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import WalletInfo from "../../WalletInfo";

const Header = () => {
  const {connected} = useWallet();
  return (
    <header className={`app-header navbar-purple navbar-sticky`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav
              className="navbar navbar-expand-md align-items-center"
              id="mainNav">
              <a
                className="navbar-brand me-5 d-flex align-items-center"
                href="/">
                <img src={NexIQ_Logo} alt="NexIQ Logo" width={80} height={80} />
              </a>

              {/* <div className="d-flex align-items-center">
                <WalletInfo />
              </div> */}

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarsExampleDefault"
                aria-controls="navbarsExampleDefault"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">
                  <i className="fa fa-bars"> </i>
                </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarsExampleDefault">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger active" to="/">
                      Home
                    </Link>
                  </li>
                  {connected && (
                    <li className="nav-item">
                      <Link className="nav-link js-scroll-trigger" to="/trade">
                        Trade
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger" to="/#about">
                      Tokenomics
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger" to="/#roadmap">
                      Roadmap
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger" to="/#contact">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <WalletInfo />
                  </li>
                  {/* <li className="nav-item">
                  <w3m-button />

                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
