import React from 'react';
import styled from 'styled-components';

const ShapesDiv = styled.div`
  position: relative;
  // height: 100vh;
  // width: 100vw;
`;

const Shape = styled.div`
  will-change: transform;
  position: absolute;
  border-radius: 50%;
  background: #071129;
  box-shadow: inset -25px -25px 50px #152758, inset 25px 25px 50px #000000;
`;

const Shape1 = styled(Shape)`
  width: 700px;
  height: 700px;
  margin: -500px 0 0 -500px;

  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
    margin: -250px 0 0 -250px;
  }
`;

const Shape2 = styled(Shape)`
  width: 550px;
  height: 550px;
  margin: -425px 0 0 -425px;

  @media (max-width: 768px) {
    width: 400px;
    height: 400px;
    margin: -200px 0 0 -200px;
  }
`;

const Shape3 = styled(Shape)`
  width: 400px;
  height: 400px;
  margin: -350px 0 0 -350px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    margin: -150px 0 0 -150px;
  }
`;

const Shapes = () => {
  return (
    <ShapesDiv className="shapes">
      <Shape1 className="shape shape-1" />
      <Shape2 className="shape shape-2" />
      <Shape3 className="shape shape-3" />
    </ShapesDiv>
  );
};

export default Shapes;
