import React from 'react';
import { Layout, Row, Col, Card, Typography } from 'antd';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const { Header, Content, Footer } = Layout;
const { Title: AntTitle, Paragraph } = Typography;

const data = {
  labels: ['Public Sale', 'Private Sale', 'Team Allocation', 'Reserves'],
  datasets: [{
    data: [30, 20, 20, 30], // Example data
    backgroundColor: ['#ff6384', '#36a2eb', '#cc65fe', '#ffce56'],
  }]
};

const TokenomicsPage = () => {
  return (
    <Layout style={{marginTop:"100px" }}>
      <Header style={{ background: '#071129', padding: '0 20px' }} className="text-center">
        <AntTitle style={{ color: '#fff', lineHeight: '64px', fontSize: '36px' }} level={2}>Tokenomics</AntTitle>
      </Header>
      <Content className="container mt-5">
        <Row className="g-4">
          <Col md={24} className="mx-auto">
            <Card title="Introduction" bordered={false} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Paragraph >
                Our token, NIQ, is designed to empower our ecosystem by providing utility and incentivizing participation.
              </Paragraph>
              <Paragraph>
                We have a total supply of 24 million NIQ tokens on the TRC20 Tron network.
              </Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={24} className="mx-auto">
            <Card title="Token Distribution and Allocation" bordered={false} className="shadow-sm p-3 mb-5 bg-white rounded">
                <Row className='align-items-center'>
                <Col md={12}>
              <Pie data={data} options={{ responsive: true, plugins: { title: { display: true, text: 'Token Distribution' } } }} />
              </Col>
              <Col md={12}>

              <Paragraph style={{ marginTop: '20px' }}>
                Here is a detailed breakdown of how our tokens are distributed:
              </Paragraph>
              <ul>
                <li>Public Sale: 30% (7.2 million NIQ)</li>
                <li>Private Sale: 20% (4.8 million NIQ)</li>
                <li>Team Allocation: 20% (4.8 million NIQ)</li>
                <li>Reserves: 30% (7.2 million NIQ)</li>
              </ul>
              </Col>
              </Row>
            </Card>

          </Col>
          <Col xs={12} md={6} className="mx-auto">
            <Card title="Use Cases" bordered={false} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Paragraph>NIQ tokens can be used for staking, governance, and accessing premium features within our platform.</Paragraph>
            </Card>
          </Col>
          <Col xs={12} md={6} className="mx-auto">
            <Card title="Incentives and Rewards" bordered={false} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Paragraph>Token holders are eligible for staking rewards and can participate in our governance process.</Paragraph>
            </Card>
          </Col>
        
          <Col xs={12} md={6} className="mx-auto">
            <Card title="Legal and Compliance" bordered={false} className="shadow-sm p-3 mb-5 bg-white rounded">
              <Paragraph>We ensure full compliance with relevant regulations and legal frameworks.</Paragraph>
            </Card>
          </Col>
        </Row>
      </Content>
      
      <Footer className="text-center py-3 bg-dark text-light">
        ©2024 NIQ Token. All Rights Reserved.
      </Footer>
    </Layout>
  );
};

export default TokenomicsPage;
