import React, { useState } from "react";
import styled from "styled-components";

const Section = styled.div`
  z-index: 10;
  position: relative;
  padding: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 50px 0;
  }
`;

const Title = styled.div`
  width: 100%;
  color:  #848b9f;
  text-align: center;
  &:hover {
    color: #fff;
  }

  @media (min-width: 768px) {
    width: 50%;
    text-align: left;
  }
`;

const Years = styled.div`
  width: 100%;
  ul {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;

    li {
      padding-right: 20px;
      font-family: "Lexend", sans-serif;
      color: #848b9f;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      transition: all 0.8s ease;
      cursor: pointer;
      &:hover,
      &.active {
        color: #000;
      }
    }
  }

  @media (min-width: 768px) {
    width: 50%;
    ul {
      justify-content: flex-end;
      li {
        padding-right: 50px;
      }
    }
  }
`;

const RoadmapContent = styled.div`
  width: 100%;
  padding: 60px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const Quarter = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 20px;

  .quarter-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.8s ease;
    h2 {
      color: #848b9f;
    }
  }

  .quarter-content {
    opacity: 0;
    transition: all 0.8s ease;
    ul {
      list-style: none;
      padding: 0;
    }
  }

  &.active .quarter-title {
    opacity: 0;
  }

  &.active .quarter-content {
    opacity: 1;
    color: white !important;
  }

  @media (min-width: 768px) {
    width: 16.66%;
    margin-bottom: 0;
  }
`;

const RoadmapSection = () => {
  const [activeQuarter, setActiveQuarter] = useState("");

  const handleMouseEnter = (quarter) => {
    setActiveQuarter(quarter);
  };

  const roadmapData = {
    "q1-2024": [
      "Project ideation and team formation.",
      "Market research and technical feasibility."
    ],
    "q2-2024": [
      "Smart contract development and tokenomics design.",
      "Whitepaper drafting."
    ],
    "q3-2024": [
      "Testnet deployment and smart contract audit.",
      "Website development and community engagement."
    ],
    "q4-2024": [
      "Private sale and public sale/ICO.",
      "Marketing campaigns and airdrops."
    ],
    "q1-2025": [
      "Mainnet deployment and exchange listing.",
      "Post-launch development and community support."
    ],
    "q2-2025": [
      "Continuous development and feature implementation.",
      "Partnerships, collaborations, and regular updates."
    ]
  };

  return (
    <Section className="roadmap-section">
      <TitleContainer>
        <Title>
          <p>Roadmap</p>
          <h2>Future Plans</h2>
        </Title>
      </TitleContainer>
      <RoadmapContent>
        {Object.keys(roadmapData).map((key, index) => (
          <Quarter
            key={key}
            className={activeQuarter === key ? "active" : ""}
            onMouseEnter={() => handleMouseEnter(key)}
          >
            <div className="quarter-title">
              <h2>Q{index % 4 + 1}</h2>
              <h2>{key.split("-")[1]}</h2>
            </div>
            <div className="quarter-content">
              <h4>Q{index % 4 + 1} - {key.split("-")[1]}</h4>
              <ul>
                {roadmapData[key].map((item, idx) => (
                  <li key={idx}>
                    <p>{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          </Quarter>
        ))}
      </RoadmapContent>
    </Section>
  );
};

export default RoadmapSection;
