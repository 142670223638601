import "./index.scss";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import NexIQ from "../../../assets/NexIQ_Logo.webp";
import NIQ from "../../../assets/NIQ_Logo.png";

const Hero = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div className="hero-banner creative-banner" id="home">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: "rgb(255,255,255)",
          },
          fpsLimit: 60,
          particles: {
            shape: {
              type: "circle",
            },
            size: {
              random: {
                enable: true,
                minimumValue: 0.4,
              },
              value: 1.4,
            },
            color: {
              value: "#000000",
            },
            number: {
              density: {
                enable: true,
                area: 1080,
              },
              limit: 0,
              value: 200,
            },
            opacity: {
              animation: {
                enable: true,
                minimumValue: 0.5,
                speed: 1.6,
                sync: false,
              },
              random: {
                enable: true,
                minimumValue: 0.1,
              },
              value: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
            },
          },
        }}
      />
      <div className="hero-text">
        <div className="row align-items-center mt-5">
          <div className="col-lg-6 col-md-6 text-center">
            <img className="banner-img" src={NIQ} alt="Niq Logo" width="510" />
            <h1 className="title">NIQ</h1>
            <h3 className="fs-4 title">(NIQ)</h3>
          </div>
          <div
            className="col-lg-6 col-md-6 wow zoomIn text-center"
            data-wow-delay=".6s"
            style={{
              visibility: "visible",
              animationDelay: "0.6s",
              animationName: "zoomIn",
            }}
          >
            <img className="banner-img" src={NexIQ} alt="NexIq Logo" width="500" />
            <h1 className="title">NexIQ</h1>
            <h3 className="fs-4 title">(USN)</h3>
          </div>
        </div>
      </div>
      <div
        className="hero-footer"
        style={{
          backgroundImage:
            'url("https://thevectorlab.net/kryplab/assets/img/banner-curve-dark.png")',
        }}
      ></div>
    </div>
  );
};

export default Hero;
