import React from "react";
import "./index.scss";
import Shapes from "./Shapes";
import RoadmapSection from "./RoadmapSection";

function Roadmap() {
  return (
    <div className="container" id="roadmap">
      <div className="background">
        <Shapes />
      </div>
      <RoadmapSection />
    </div>
  );
}

export default Roadmap;
