import React, { useState, useEffect } from "react";
import "./index.scss";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Tooltip, Modal, List, Input, AutoComplete } from "antd";

const { Search } = Input;

// Example list of countries to be colored
const countryColors = {
  RUS: "#b23456", // Red
  CAN: "lime", // Green
  MEX: "#0000FF", // Blue
  IND: "#d2691e",
  ARE: "#FFAFF0",
  // Add more countries and colors as needed
};

// Example data for countries
const countryData = {
  USA: ["New York", "Los Angeles", "Chicago"],
  CAN: ["Toronto", "Vancouver", "Montreal"],
  MEX: ["Mexico City", "Guadalajara", "Monterrey"],
  IND: ["Delhi", "Mumbai", "Bangalore"],
  // Add more data as needed
};

const WorldMap = () => {
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [clickedCountry, setClickedCountry] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [geoData, setGeoData] = useState([]);

  useEffect(() => {
    fetch("/features.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (
          data.objects.world.geometries &&
          Array.isArray(data.objects.world.geometries)
        ) {
          setGeoData(data.objects.world.geometries);
          const options = data.objects.world.geometries.map((feature) => ({
            value: feature.properties.name,
            isoCode: feature.id,
          }));
          setCountryOptions(options);
        } else {
          throw new Error("Invalid data format");
        }
      })
      .catch((error) => {
        console.error("Error fetching the features.json file:", error);
      });
  }, []);

  const handleCountryClick = (isoCode) => {
    if (countryData[isoCode]) {
      setClickedCountry(isoCode);
      setIsModalVisible(true);
    } else {
      setClickedCountry(isoCode);
      setIsModalVisible(true);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setClickedCountry(null);
  };

  const onSearch = (value) => {
    const selectedCountry = countryOptions.find(
      (option) => option.value.toLowerCase() === value.toLowerCase()
    );
    if (selectedCountry) {
      handleCountryClick(selectedCountry.isoCode);
    }
  };

  return (
    <div
      style={{
        zIndex: 400,
        position: "relative",
        background: "#071129",
        padding: 0,
        textAlign: "center",
      }}
      className="pt-5 abc section-sm world-map"
    >
      <AutoComplete
        style={{ width: 300, margin: "20px auto", display: "block"}}
        options={countryOptions}
        className="w-50"
        onSelect={(value) => onSearch(value)}
        filterOption={(inputValue, option) =>
          option.value.toLowerCase().includes(inputValue.toLowerCase())
        }
      >
        <Search
          placeholder="Search for a country"
          enterButton
          size="large"
          onSearch={onSearch}
          className="custom-search-input bg-transparent"
        />
      </AutoComplete>
      <ComposableMap style={{ width: "100%", height: "1000px" }}>
        <Geographies geography="/features.json">
          {({ geographies }) =>
            geographies.map((geo) => {
              const isoCode = geo.id;
              const fillColor = countryColors[isoCode] || "#383d46";
              const countryName = geo.properties.name;

              return (
                <Tooltip key={geo.rsmKey} title={countryName}>
                  <Geography
                    geography={geo}
                    fill={fillColor}
                    onMouseEnter={() => setHoveredCountry(isoCode)}
                    onMouseLeave={() => setHoveredCountry(null)}
                    onClick={() => handleCountryClick(isoCode)}
                    style={{
                      default: { outline: "none" },
                      hover: { fill: "#000", outline: "none" },
                      pressed: { fill: "#fff", outline: "none" },
                    }}
                  />
                </Tooltip>
              );
            })
          }
        </Geographies>
      </ComposableMap>

      <Modal
        title={clickedCountry ? `Information about ${clickedCountry}` : ""}
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <List
          dataSource={countryData[clickedCountry] || ["No data available"]}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </Modal>
    </div>
  );
};

export default WorldMap;
