import './index.scss';

import React, { useState } from 'react';

import { message } from 'antd';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: '' });
  };

  const validateForm = () => {
    const { name, email, subject, message } = formData;
    const newErrors = {};
    if (!name) newErrors.name = 'Please enter your name';
    if (!email) newErrors.email = 'Please enter your email';
    if (email && !/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Please enter a valid email';
    if (!subject) newErrors.subject = 'Please enter Subject';
    if (!message) newErrors.message = 'Write your message';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await fetch('https://aestraswift.ocpl.tech/api/nexiq/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        message.success('Your query has been sent successfully!');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        message.error('Failed to send your query. Please try again later.');
      }
    } catch (error) {
      message.error('An error occurred. Please try again later.');
    }
  };

  return (
    <div id="contact">
      <section className="feature-gradient ">
        <div className="row">
          <div className="col-12">
            <div
              id="contact-box"
              className="card border-0 text-black px-md-4 wow fadeInUp bg-abc"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInUp",
              }}
            >
              <div className="card-body">
                <div className="row justify-content-md-center text-center">
                  <div className="col-md-8 col-12">
                    <div className="py-3">
                      <h3 className="mb-0">Contact NexIQ</h3>
                      <p>
                        Whether you have questions, feedback, or simply want
                        to chat, we're here for you. Reach out and let's
                        connect.
                      </p>
                    </div>

                    <form
                      id="contactForm"
                      className="contact-form text-left"
                      data-toggle="validator"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              id="name"
                              className="form-control mb-3"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                            <div className="help-block with-errors">
                              {errors.name}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              className="email form-control mb-3"
                              id="email"
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                            <div className="help-block with-errors">
                              {errors.email}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="controls">
                          <input
                            type="text"
                            id="subject"
                            className="form-control mb-3"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                          <div className="help-block with-errors">
                            {errors.subject}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="controls">
                          <textarea
                            id="message"
                            rows="4"
                            placeholder="Message"
                            className="form-control mb-3"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                          <div className="help-block with-errors">
                            {errors.message}
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt-md-5">
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-purple submit-button btn-pill"
                          style={{
                            pointerEvents: "all",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </button>
                        <div id="msgSubmit" className="h5 text-center hidden"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* <form
              className="subscribe-from form-inline row mt-5 wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
            >
              <div className="form-group col-md-4">
                <h4 className="m-0 text-uppercase">Subscribe</h4>
                <small>Never miss our any news and updates</small>
              </div>
              <div className="form-group col-sm-5">
                <input
                  type="email"
                  className="form-control w-100"
                  placeholder="Enter your email address"
                />
              </div>
              <div className="form-group col-sm-3">
                <button
                  type="submit"
                  className="btn btn-light"
                >
                  subscription
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
