import "./App.css";

import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AboutUs from "./components/views/about";
import ContactUs from "./components/views/contactUs";
import Cursor from "./Cursor";
import Footer from "./components/common/footer";
import Header from "./components/common/header";
import Hero from "./components/views/hero";
import PageNotFound from "./components/views/PageNotFound";
import Partners from "./components/views/partners";
import Roadmap from "./components/views/roadmap";
import TokenomicsPage from "./components/views/tokenomics";
import Trade from "./components/views/trade";
import WorldMap from "./components/views/worldMap";
import TransparencyComponent from "./components/views/collateral";
import {
  useWallet,
  WalletProvider,
} from "@tronweb3/tronwallet-adapter-react-hooks";
// import Web3ModalProvider from "./WalletConnect";
import BalanceContext from "./components/hooks/BalanceContext";

const HomePage = () => {
  return (
    <>
      <Hero />
      <AboutUs />
      <Roadmap />
      <WorldMap />
      <TransparencyComponent />
      <ContactUs />
    </>
  );
};

function App() {
  const { connected } = useWallet();

  return (
    <>
      <Cursor />
      <Header />

      {connected && (
        <div className="chatbot-icon">
          <i className="mdi mdi-robot"></i>
        </div>
      )}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tokenomics" element={<TokenomicsPage />} />
        <Route path="*" element={<PageNotFound />} />
        <Route
          path="/trade"
          element={connected ? <Trade /> : <PageNotFound />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
