import React, { useState, useEffect, useContext } from "react";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  Dropdown,
  Button,
  Menu,
  Modal,
  Input,
  Select,
  notification,
} from "antd";
import {
  DownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import BalanceContext from "./hooks/BalanceContext";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

const WalletInfo = () => {
  const { address, connected, connect, disconnect, select } = useWallet();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tronLinkModalVisible, setTronLinkModalVisible] = useState(false);
  const { balanceTRX, balanceUSDT, fetchBalances } = useContext(BalanceContext);
  const [currency, setCurrency] = useState("TRX");

  const { Option } = Select;
  const { control, setValue, watch } = useForm();
  const openNotificationWithIcon = (
    type,
    messageText,
    description,
    txId = null
  ) => {
    const btn = txId ? (
      <Button
        type="default"
        onClick={() =>
          window.open(`https://tronscan.org/#/transaction/${txId}`, "_blank")
        }
        style={{ borderColor: "#faad14", color: "#faad14" }}>
        Pending: {txId.slice(0, 4)}...{txId.slice(-4)}
      </Button>
    ) : null;

    notification[type]({
      message: messageText,
      description: description,
      icon:
        type === "success" ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        ),
      style: {
        backgroundColor: type === "success" ? "#f6ffed" : "#fff2f0",
        borderColor: type === "success" ? "#b7eb8f" : "#ffa39e",
      },
      showProgress: true,
      pauseOnHover: true,
      btn,
    });
  };

  const handleConnect = async () => {
    try {
      if (window.tronWeb) {
        await select("TronLink"); // Ensure the TronLink adapter is selected
        await connect();

      } else {
        setTronLinkModalVisible(true);
      }
    } catch (error) {
      console.error("Failed to connect wallet:", error);
      // openNotificationWithIcon("error", "Error", "Failed to connect wallet.");
    }
  };
  // const checkFirstTime  =async () => {
  //   const response = await axios.post(
  //     "https://aestraswift.ocpl.tech/api/nexiqRoutes/check-first-timer",
  //     { accountId: address }
  //   );

  //   if (!response.exists) {
  //     // Ask for permission to trade with TRX and USDT
  //     const userConsent = window.confirm(
  //       "Do you want to trade with TRX and USDT and pay the one-time fee?"
  //     );

  //     if (userConsent) {
  //       // Pay the one-time fee
  //       const feeAmount = 55; // Example fee amount in Sun (1 TRX)
  //       const myWalletAddress = "TZ99rkWHE76a1v5xHnjhQzvjd5oFG6ZSM4";
  //       const tronWeb = window.tronWeb;

  //       const tx = await tronWeb.trx.sendTransaction(
  //         myWalletAddress,
  //         tronWeb.toSun(feeAmount)
  //       );

  //       // Proceed with the transaction
  //       // Add your transaction logic here
  //       // Assuming SwapCurrency is the function for handling the swap
  //     } else {
  //       // Disconnect the wallet
  //       await disconnect();
  //       openNotificationWithIcon(
  //         "info",
  //         "Disconnected",
  //         "Wallet disconnected as you cancelled the permission."
  //       );
  //     }
  //   } else {
  //     openNotificationWithIcon(
  //       "error",
  //       "Error",
  //       "Permission denied to trade with TRX and USDT."
  //     );
  //   }
  // }
  // const handleConnect = async () => {
  //   try {
  //     if (window.tronWeb) {
  //       await select("TronLink"); // Ensure the TronLink adapter is selected
  //       await connect();
  //       await checkFirstTime();
  //       // Get the address of the connected wallet

  //       // Call the API with the address using axios
  //       console.log("After connection");
      
  //     } else {
  //       setTronLinkModalVisible(true);
  //     }
  //   } catch (error) {
  //     console.error("Failed to connect wallet:", error);
  //     openNotificationWithIcon("error", "Error", "Failed to connect wallet.");
  //   }
  // };
  // const handleConnect = async () => {
  //   try {
  //     if (window.tronWeb) {
  //       await select("TronLink"); // Ensure the TronLink adapter is selected
  
  //       try {
  //         const address = window.tronWeb.defaultAddress.base58;
  //         await checkFirstTime(address);
  //         await connect();
  
  //         // Get the address of the connected wallet
  
  //         // Check if the user is a first-time trader
  
  //       } catch (connectError) {
  //         console.error("Failed to connect wallet:", connectError);
  //         openNotificationWithIcon("error", "Error", "Failed to connect wallet.");
  //       }
  //     } else {
  //       setTronLinkModalVisible(true);
  //     }
  //   } catch (error) {
  //     console.error("Error during wallet connection process:", error);
  //     openNotificationWithIcon("error", "Error", "An error occurred during the wallet connection process.");
  //   }
  // };
  


  const formatAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(-6)}`;
  };

  const handleCopyAddress = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      openNotificationWithIcon(
        "success",
        "Success",
        "Address copied to clipboard!"
      );
    } else {
      openNotificationWithIcon("error", "Error", "No address found to copy.");
    }
  };

  const showTransactionModal = () => {
    setIsModalVisible(true);
  };

  const getMaxBalance = () => {
    return currency === "TRX" ? balanceTRX : balanceUSDT;
  };

  const handleOk = async () => {
    const amount = watch("amount");
    if (
      Number(getMaxBalance()) === 0 ||
      Number(amount) > Number(getMaxBalance())
    ) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Insufficient Balance in Account!"
      );
      return; // Exit the function
    }

    try {
      const tronWeb = window.tronWeb;
      const recipientAddress = watch("recipientAddress");

      if (currency === "TRX") {
        const tx = await tronWeb.trx.sendTransaction(
          recipientAddress,
          tronWeb.toSun(amount)
        );
        console.log(tx, "TX");
        openNotificationWithIcon(
          "success",
          "Success",
          "TRX transaction sent!",
          tx.txid
        );

        fetchBalances();
      } else if (currency === "USDT") {
        const usdtContractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"; // Replace with actual USDT contract address
        const usdtContract = await tronWeb.contract().at(usdtContractAddress);
        const tx = await usdtContract
          .transfer(recipientAddress, tronWeb.toSun(amount))
          .send();
        console.log(tx, "TX");

        openNotificationWithIcon(
          "success",
          "Success",
          "USDT transaction sent!",
          tx.txid
        );
        fetchBalances();
      }

      setIsModalVisible(false);
      setValue("amount", "");
      setValue("recipientAddress", "");

      openNotificationWithIcon(
        "success",
        "Transaction successful!",
        "Your balance will be updated once the blockchain confirms the transaction block in approximately 1 minute."
      );

      setTimeout(() => {
        window.location.reload();
      }, 60000); // Refresh the page after 1 minute
    } catch (error) {
      console.error("Failed to send transaction:", error);
      openNotificationWithIcon("error", "Error", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTronLinkModalOk = () => {
    setTronLinkModalVisible(false);
  };

  const menuItems = [
    {
      key: "1",
      label: `Balance: ${
        balanceTRX !== null ? `${balanceTRX} TRX` : "Fetching balance..."
      }`,
    },
    {
      key: "2",
      label: `USDT Balance: ${
        balanceUSDT !== null ? `${balanceUSDT} USDT` : "Fetching balance..."
      }`,
    },
    {
      key: "3",
      label: "Copy Address",
      onClick: handleCopyAddress,
    },
    {
      key: "4",
      label: "Send Transaction",
      onClick: showTransactionModal,
    },
    {
      key: "5",
      label: "Disconnect Wallet",
      onClick: disconnect,
    },
  ];

  const handleCurrencyChange = (value) => {
    setCurrency(value);
  };

  const menu = <Menu items={menuItems} />;

  return (
    <div style={{ fontFamily: "Arial, sans-serif" }}>
      {connected ? (
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button
            type="primary"
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            style={{ padding: "10px 20px", borderRadius: "30px" }}>
            {formatAddress(address)} <DownOutlined />
          </Button>
        </Dropdown>
      ) : (
        <Button
          type="primary"
          onClick={handleConnect}
          style={{ padding: "10px 20px", borderRadius: "30px" }}>
          Connect Wallet
        </Button>
      )}
      <Modal
        title="Send Transaction"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <div className="py-5">
          <div className="mb-3 position-relative">
            <div className="text-end">
              <small className="text-white ms-auto">
                Balance: {currency === "USDT" && balanceUSDT}{" "}
                {currency === "TRX" && balanceTRX}
                <span className="fw-bold"> {currency}</span>
              </small>
            </div>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  step="any"
                  max={getMaxBalance()}
                  placeholder="Enter amount"
                  min="0"
                  className="flex-grow-1 position-relative"
                />
              )}
            />
            <Select
              value={currency}
              className="currency-select mt-2"
              onChange={handleCurrencyChange}>
              <Option value="USDT">USDT</Option>
              <Option value="TRX">TRX</Option>
            </Select>
          </div>
          <Controller
            name="recipientAddress"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Recipient Address" />
            )}
          />
        </div>
      </Modal>
      <Modal
        title="TronLink Extension Required"
        visible={tronLinkModalVisible}
        onOk={handleTronLinkModalOk}
        onCancel={handleTronLinkModalOk}
        footer={[
          <Button key="ok" type="primary" onClick={handleTronLinkModalOk}>
            OK
          </Button>,
        ]}>
        <p className="text-white">
          For your financial security, please install the official TronLink
          extension from the{" "}
          <a
            href="https://chromewebstore.google.com/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec"
            target="_blank"
            rel="noopener noreferrer">
            Chrome Web Store
          </a>{" "}
          to connect your wallet.
        </p>
        <p className="text-white">
          After installing the extension, please refresh the page.
        </p>
      </Modal>
    </div>
  );
};

export default WalletInfo;
